// src/pages/khoji/index.js
import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types'; // Import PropTypes
import { Layout } from '@components';

const StyledBitmojiContainer = styled.main`
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem;

  h1 {
    text-align: center;
    margin-bottom: 2rem;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10rem;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }

  label {
    font-size: 18px;
    margin-bottom: 10px;
  }

  input[type='text'] {
    width: 200px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #cccccc;
    border-radius: 5px;
  }

  input[type='submit'] {
    font-size: 18px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }

  .bitmoji-container {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }

  .bitmoji {
    width: 300px;
    height: auto;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  }

  footer {
    padding: 20px;
    text-align: center;
  }

  .credit {
    margin: 10px 0;
  }

  .credit h2,
  .credit h3 {
    margin: 0;
  }

  .credit a {
    color: #0077b5;
    font-weight: bold;
    text-decoration: none;
  }

  .credit a:hover {
    text-decoration: underline;
  }
`;

const BitmojiPage = ({ location }) => {
  const [username, setUsername] = useState('');
  const [bitmojiLinks, setBitmojiLinks] = useState([]);

  const getBitmojiLinks = async username => {
    const initUrl = `https://snapchat.com/add/${username}`;
    const headers = {
      accept:
        'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7',
      'accept-language': 'en-GB,en-US;q=0.9,en;q=0.8,pt;q=0.7',
      'cache-control': 'max-age=0',
      'upgrade-insecure-requests': '1',
      origin: 'https://www.snapchat.com',
    };

    try {
      const response = await fetch(initUrl, { headers });
      // console.log('API response:', response.data);
      const parser = new DOMParser();
      const doc = parser.parseFromString(response.data, 'text/html');
      const pictureElements = doc.querySelectorAll('picture');
      // console.log('Picture elements:', pictureElements);

      if (pictureElements.length === 0) {
        setBitmojiLinks(['https://www.freeiconspng.com/thumbs/no-image-icon/no-image-icon-10.png']);
        return;
      }

      const bitmojiLinks = [];
      pictureElements.forEach(picture => {
        const sourceElement = picture.querySelector('source');
        if (sourceElement) {
          const srcset = sourceElement.getAttribute('srcset') || '';
          if (srcset.startsWith('https://images.bitmoji.com/3d/avatar/')) {
            const count = parseInt(srcset.split('_')[1].split('.')[0]);
            const part1 = srcset.split('_')[0];
            const part2 = srcset.slice(-11);

            for (let i = 0; i < count; i++) {
              const link1 = `${part1}_${i}${part2}`;
              bitmojiLinks.push(link1);
              const index = part1.lastIndexOf('-');
              const number = part1.substring(index + 1);
              const link2 = `https://images.bitmoji.com/3d/avatar/582513516-${number}_${i}${part2}`;
              bitmojiLinks.push(link2);
            }
          }
        }
      });

      // console.log('Bitmoji links:', bitmojiLinks);
      setBitmojiLinks(bitmojiLinks);
    } catch (error) {
      // console.error("Failed to fetch Bitmoji links:", error);
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    getBitmojiLinks(username);
  };

  return (
    <Layout location={location}>
      <StyledBitmojiContainer>
        <form onSubmit={handleSubmit}>
          <h1>کھوجی</h1>
          <h2>OSINT tool to view prevoius bitmojis of any snapchat user.</h2>
          <label htmlFor="username">Enter Snapchat Username:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={e => setUsername(e.target.value)}
            required
          />
          <input type="submit" value="View Bitmojis" />
        </form>
        <div className="bitmoji-container">
          {bitmojiLinks.length > 0 &&
            bitmojiLinks.map((link, index) => (
              <img key={index} className="bitmoji" src={link} alt="Bitmoji" />
            ))}
        </div>
        <footer>
          <div className="credit">
            <h2>
              Star the GitHub repo at{' '}
              <a
                href="https://www.github.com/asharbinkhalil/khoji/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-github"></i> khoji
              </a>
            </h2>
          </div>
          <div className="credit">
            <h3>
              Inspired by{' '}
              <a href="https://backmoji.myosint.training" target="_blank" rel="noopener noreferrer">
                Backmoji
              </a>{' '}
              by{' '}
              <a href="https://github.com/webbreacher" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-github"></i> Micah Hoffman
              </a>
            </h3>
          </div>
        </footer>
      </StyledBitmojiContainer>
    </Layout>
  );
};

BitmojiPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default BitmojiPage;
